import { fabric } from "fabric";
import MemeRendererBase from "./renderer_base";

export class MemeRenderer extends MemeRendererBase<fabric.StaticCanvas> {
  constructor(private element: HTMLCanvasElement, private width: number, height: number) {
    super(new fabric.StaticCanvas(element, {
      width: width,
      height: height,
    }));
  }
}