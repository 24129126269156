import { fabric } from "fabric";

export class AutofitTextbox extends fabric.Textbox {

  textCenterV?: boolean | undefined;
  autoSize?: boolean | undefined;
  minFontSize: number = 1.0;
  maxFontSize: number = 80.0;
  uppercase?: boolean | undefined;

  /**
   * Constructor
   * @param {String} text Text string
   * @param {Object} [options] Options object
   * @return {fabric.IText} thisArg
   */
  constructor(text: string, options: any) {
    super(text, options);
    this.on('scaling', this._handleScaling);
  }

  _render(ctx: any) {
    if (this.uppercase) {
      (this as any).set({
        'text': this.text?.toUpperCase()
      });
    }
    super._render(ctx);
  }

  _renderBackground(ctx: any) {
    if (!this.backgroundColor) {
      return;
    }
    var dim = this._getNonTransformedDimensions();
    ctx.fillStyle = this.backgroundColor;

    ctx.fillRect(
      -dim.x / 2 - (this.padding ?? 0),
      -dim.y / 2 - (this.padding ?? 0),
      dim.x + (this.padding ?? 0) * 2,
      dim.y + (this.padding ?? 0) * 2
    );
    // if there is background color no other shadows
    // should be casted
    this._removeShadow(ctx);
  }

  /**
  * Wraps text using the 'width' property of Textbox. First this function
  * splits text on newlines, so we preserve newlines entered by the user.
  * Then it wraps each line using the width of the Textbox by calling
  * _wrapLine().
  * @param {Array} lines The string array of text that is split into lines
  * @param {Number} desiredWidth width you want to wrap to
  * @returns {Array} Array of lines
  */
  _wrapText(lines: any, desiredWidth: number) {
    var wrapped: any[] = []
    let i: number;
    this.isWrapping = true;
    for (i = 0; i < lines.length; i++) {
      wrapped = wrapped.concat((this as any)._wrapLine(lines[i], i, desiredWidth));
    }
    this.isWrapping = false;
    return wrapped;
  }

  /**
 * @private
 * @param {String} method Method name ("fillText" or "strokeText")
 * @param {CanvasRenderingContext2D} ctx Context to render on
 * @param {String} line Text to render
 * @param {Number} left Left position of text
 * @param {Number} top Top position of text
 * @param {Number} lineIndex Index of a line in a text
 */
  _renderTextLine(method: any, ctx: any, line: any, left: number, top: number, lineIndex: number) {
    let offset = 0;
    if (this.height && this.textCenterV) {
      offset = (this.height - this.safeCalcTextHeight()) / 2;
    }
    this._renderChars(method, ctx, line, left, top + offset, lineIndex);
  }

  /**
  * Unlike subclass's version of this function, AutofitTextbox does not 
  * automatically set height based on text lines. Also we optionally 
  * auto-size the text here to fit the shape dimensions
  * @private
  * @override
  */
  initDimensions() {
    if ((this as any).__skipDimension) {
      return;
    }
    this.isEditing && this.initDelayedCursor();
    this.clearContextTop();
    this._clearCache();
    // clear dynamicMinWidth as it will be different after we re-wrap line
    this.dynamicMinWidth = 0;
    // wrap lines
    if (this.autoSize && this.width && this.height) {
      var low = this.minFontSize
      var high = this.maxFontSize;
      var mid;
      var latestFit = low;
      while (low <= high) {
        mid = (high + low) >> 1;
        this.fontSize = mid;
        this.dynamicMinWidth = this.width;
        this._styleMap = this._generateStyleMap(this._splitText());
        if (this.dynamicMinWidth <= this.width && this.safeCalcTextHeight() <= this.height) {
          latestFit = mid;
          low = mid + 1;
        } else {
          high = mid - 1;
        }
      }
      this.fontSize = latestFit;
    }
    this._styleMap = this._generateStyleMap(this._splitText());
    if (this.textAlign && this.textAlign.indexOf('justify') !== -1) {
      // once text is measured we need to make space fatter to make justified text.
      this.enlargeSpaces();
    }
    // clear cache
    this.saveState({ propertySet: '_dimensionAffectingProps' });
  }

  /**
   * Calculate height of line at 'lineIndex'
   * @param {Number} lineIndex index of line to calculate
   * @return {Number}
   */
  safeGetHeightOfLine(lineIndex: number) {
    var line = this._textLines[lineIndex],
      // char 0 is measured before the line cycle because it nneds to char
      // emptylines
      maxHeight = this.getHeightOfChar(lineIndex, 0);
    for (var i = 1, len = line.length; i < len; i++) {
      maxHeight = Math.max(this.getHeightOfChar(lineIndex, i), maxHeight);
    }
    return maxHeight * (this.lineHeight ?? 1) * this._fontSizeMult;
  }

  /**
  * Calculate text height
  */
  safeCalcTextHeight() {
    var lineHeight, height = 0;
    if (this._textLines) {
      for (var i = 0, len = this._textLines.length; i < len; i++) {
        lineHeight = this.safeGetHeightOfLine(i);
        height += (i === len - 1 ? lineHeight / (this.lineHeight ?? 1) : lineHeight);
      }
    }
    return height;
  }

  _handleScaling() {
    (this as any).set({
      height: (this.height ?? 0) * (this.scaleY ?? 0),
      width: (this.width ?? 0) * (this.scaleX ?? 0),
      scaleY: 1,
      scaleX: 1,
    });
  }
};