import { useCallback, useEffect, useState } from "react";
import { MemeRenderer } from "./MemeRenderer";
import { MemeTemplate, MemeTemplateCaption } from "./emem";
import { PromptProps } from "../common/Prompt";

import css from './MemePrompt.module.css';


interface InputData {
  id: string;
  template: MemeTemplate;
}

interface OutputData {
  id: string;
  captions: string[],
}

export const MemePrompt = (props: PromptProps<InputData, OutputData>) => {

  const getInitialCaptions = useCallback(
    () => {
      return props.promptData.template.captions.map(
        (captionTemplate) => captionTemplate.text || ""
      );
    },
    [props.promptData.template.captions],
  )

  const [captions, setCaptions] = useState(getInitialCaptions());

  useEffect(() => {
    setCaptions(getInitialCaptions());
  }, [getInitialCaptions]);

  const handleSubmit = () => {
    props.onSubmit({
      id: props.promptData.id,
      captions: captions,
    })
  }

  const updateCaptionText = (i: number, text: string) => {
    let newCaptions = [...captions];
    newCaptions[i] = text;
    setCaptions(newCaptions);
  };

  return (
    <div className={css.MemePrompt}>
      {
        <MemeRenderer
          className={css.MemeRenderer}
          memeTemplate={props.promptData.template}
          captions={captions} />
      }
      <ol className={css.MemePromptInputs}>
        {props.promptData.template.captions.map((template: MemeTemplateCaption, i) => {
          return (
            <li className={css.MemePromptInputItem}
              key={i}>
              <input
                className={css.MemePromptInput}
                type='text'
                defaultValue={captions[i]}
                autoFocus={true}
                style={{
                  textTransform: template.uppercase ? 'uppercase' : undefined,
                }}
                onChange={(event) => updateCaptionText(i, event.target.value)}
              />
            </li>)
        })}
        <li>
          <button
            className={css.MemePromptSubmitButton}
            onClick={handleSubmit}
          >
            SUBMIT
          </button>
        </li>
      </ol>
    </div >
  );
}