import { useState } from "react";

import css from './TextPrompt.module.css';
import { PromptProps } from "../common/Prompt";

interface InputData {
  id: string;
  prompts: string[];
  initialValues?: string[];
  maxInputLength?: number;
  uppercase?: boolean;
}

interface OutputData {
  id: string;
  values: string[];
}

export const TextPrompt = (props: PromptProps<InputData, OutputData>) => {
  const [values, setValues] = useState<string[]>(
    props.promptData.initialValues ?? Array<string>(props.promptData.prompts.length).fill('')
  );

  const handleInputChange = (index: number, value: string) => {
    if (props.promptData.uppercase) {
      value = value.toUpperCase();
    }
    let newValues = values;
    newValues[index] = value;
    setValues(newValues);
  }

  const handleSubmitClick = () => {
    props.onSubmit({
      values: values,
      id: props.promptData.id,
    })
  }

  const renderInputField = (index: number, prompt: string, maxLength?: number, uppercase?: boolean) => {
    return (
      <div className={css.TextPromptField} key={index}>
        <label className={css.TextPromptLabel} htmlFor={`textinput_${index}`}>
          {prompt}
        </label>
        <input
          className={css.TextPromptInput}
          name={`textinput_${index}`}
          id={`textinput_${index}`}
          type='text'
          defaultValue={values[index]}
          maxLength={maxLength}
          style={{ textTransform: (uppercase ? 'uppercase' : 'none') }}
          onChange={(event) =>
            handleInputChange(index, event.target.value.toUpperCase())
          }
        />
      </div>
    );
  };

  return (
    <div className={css.TextPrompt}>
      {props.promptData.prompts.map((prompt, index) =>
        renderInputField(
          index,
          prompt,
          props.promptData.maxInputLength,
          props.promptData.uppercase
        )
      )}
      <button
        className={css.TextPromptSubmitButton}
        onClick={handleSubmitClick}
      >
        SUBMIT
      </button>
    </div>
  );
}