import { fabric } from "fabric";
import {
  Color,
  MemeTemplate,
  MemeTemplateCaption,
  Rect,
} from "./meme_template";
import { AutofitTextbox } from "./AutofitTextbox";

const TEXT_PADDING = 1;

export default class MemeRendererBase<CanvasType extends fabric.StaticCanvas> {
  readonly MAX_FONT_SIZE = 80;
  readonly MIN_FONT_SIZE = 5;

  protected image?: fabric.Image;
  protected captions?: AutofitTextbox[] = [];

  constructor(protected canvas: CanvasType) {}

  public loadTemplate = async (template: MemeTemplate) => {
    this.canvas.clear();
    this.image = await this.loadImage(template.image);
    this.captions = [];
    if ((this.image.width ?? 0) > (this.image.height ?? 0)) {
      this.image.scaleToWidth(this.canvas.getWidth());
    } else {
      this.image.scaleToHeight(this.canvas.getWidth());
    }
    this.image.selectable = false;
    this.image.evented = false; // prevent image from moving to front when selected
    this.canvas.add(this.image);
    this.image.sendToBack();
    this.image.center();
    for (const caption of template.captions) {
      const captionTextbox = await this.memeCaptionToTextbox(caption);
      this.addCaptionTextbox(captionTextbox);
    }
    this.canvas.renderAll();
  };

  public setCaptions(captions: string[]) {
    if (this.captions) {
      if (this.captions.length !== captions.length) {
        throw new Error("Caption length mismatch.");
      }
      for (var i = 0; i < captions.length; i++) {
        this.captions[i].set({ text: captions[i] });
      }
      this.canvas.requestRenderAll();
    }
  }

  public setCaptionText(i: number, captionText: string) {
    if (this.captions) {
      this.captions[i].set({ text: captionText });
      this.canvas.requestRenderAll();
    }
  }

  public resize(width: number, height: number) {
    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
    this.canvas.calcOffset();
  }

  protected addCaptionTextbox(captionTextbox: AutofitTextbox) {
    this.canvas.add(captionTextbox);
    this.captions?.push(captionTextbox);
  }

  protected async memeCaptionToTextbox(
    caption: MemeTemplateCaption
  ): Promise<AutofitTextbox> {
    const area = this.transformRectToCanvas(caption.area);
    const captionTextbox = new AutofitTextbox(caption.text, {});
    captionTextbox.editable = true;
    captionTextbox.padding = TEXT_PADDING;
    captionTextbox.left = area.x + TEXT_PADDING;
    captionTextbox.top = area.y + TEXT_PADDING;
    captionTextbox.width = area.width - 2 * TEXT_PADDING;
    captionTextbox.height = area.height - 2 * TEXT_PADDING;
    captionTextbox.centeredRotation = false;
    captionTextbox.rotate(caption.rotation ?? 0);
    captionTextbox.uppercase = caption.uppercase;
    if (caption.centerH) {
      captionTextbox.textAlign = "center";
    }
    if (caption.centerV) {
      captionTextbox.textCenterV = true;
    }
    if (caption.color) {
      captionTextbox.fill = this.toRGBA(caption.color);
    }
    if (caption.backgroundColor) {
      captionTextbox.backgroundColor = this.toRGBA(caption.backgroundColor);
    }
    if (caption.outlineText) {
      captionTextbox.strokeWidth = caption.outlineSize;
      captionTextbox.stroke = caption.outlineColor
        ? this.toRGBA(caption.outlineColor)
        : "black";
    }
    if (caption.autosize || !caption.fontSize) {
      captionTextbox.autoSize = true;
    } else if (caption.fontSize) {
      captionTextbox.fontSize = caption.fontSize;
    }
    if (caption.fontFamily) {
      await this.loadFont(caption.fontFamily);
      captionTextbox.fontFamily = caption.fontFamily;
    }
    return captionTextbox;
  }

  protected textboxToMemeCaption(textbox: AutofitTextbox): MemeTemplateCaption {
    const x = (textbox.left ?? 0) - TEXT_PADDING;
    const y = (textbox.top ?? 0) - TEXT_PADDING;
    const width = (textbox.width ?? 0) + 2 * TEXT_PADDING;
    const height = (textbox.height ?? 0) + 2 * TEXT_PADDING;
    return {
      area: {
        x: x,
        y: y,
        width: width,
        height: height,
      },
      text: textbox.text ?? "",
      color: this.fromRGBA(textbox.fill as string),
      backgroundColor: this.fromRGBA(textbox.backgroundColor as string),
      outlineText: (textbox.strokeWidth ?? 0) > 0,
      outlineSize: textbox.strokeWidth ?? 0,
      outlineColor: this.fromRGBA(textbox.stroke as string),
      fontFamily: textbox.fontFamily ?? "",
      fontSize: textbox.fontSize ?? 0,
      autosize: textbox.autoSize ?? false,
      centerH: textbox.textAlign === "center",
      centerV: textbox.textCenterV ?? false,
      rotation: textbox.angle,
      uppercase: textbox.uppercase,
    };
  }

  private loadImage = (imageUri: string) => {
    return new Promise<fabric.Image>((resolve, _) => {
      fabric.Image.fromURL(imageUri, resolve);
    });
  };

  private transformRectToCanvas = (rect: Rect): Rect => {
    if (this.image?.width && this.image?.height) {
      const widthDiff = this.canvas.getWidth() - this.image.getScaledWidth();
      const heightDiff = this.canvas.getHeight() - this.image.getScaledHeight();
      const xScale = this.image.getScaledWidth() / this.image.width;
      const yScale = this.image.getScaledHeight() / this.image.height;
      return {
        x:
          (rect.x / this.image.width) * this.image.getScaledWidth() +
          widthDiff / 2,
        y:
          (rect.y / this.image.height) * this.image.getScaledHeight() +
          heightDiff / 2,
        width: rect.width * xScale,
        height: rect.height * yScale,
      };
    }
    return rect;
  };

  private toRGBA = (color: Color) => {
    return [
      "rgba(",
      Math.round(color.r * 255),
      ",",
      Math.round(color.g * 255),
      ",",
      Math.round(color.b * 255),
      ",",
      Math.round(color.a * 255),
      ")",
    ].join("");
  };

  private fromRGBA = (color: string): Color => {
    const rgba = color
      .substring(4, color.length - 1)
      .replace(/ /g, "")
      .split(",")
      .map((v) => parseFloat(v));
    return {
      r: rgba[0] / 1000,
      g: rgba[1] / 1000,
      b: rgba[2] / 1000,
      a: rgba[3] / 1000,
    };
  };

  private loadFont = async (font: string) => {
    await document.fonts.load(`1em ${font}`);
  };
}
