import { useState } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import { PromptProps } from "../common/Prompt";

import css from './SongPrompt.module.css';

interface InputData {
  id: string;
  apiAccessToken: string;
}

interface OutputData {
  id: string;
  trackId?: string,
  title?: string,
  artists?: string[],
}

const SEARCH_DEBOUNCE_TIME_MS = 300;

export const SongPrompt = (props: PromptProps<InputData, OutputData>) => {
  const [searchResults, setSearchResults] = useState<SpotifyApi.SingleTrackResponse[]>();
  let cancelSearch = false;

  const spotifyApi = new SpotifyWebApi();
  spotifyApi.setAccessToken(props.promptData.apiAccessToken);

  const handleSubmit = (track: SpotifyApi.SingleTrackResponse | undefined = undefined) => {
    if (track) {
      props.onSubmit({
        id: props.promptData.id,
        trackId: track.id,
        title: track.name,
        artists: track.artists.map((artist) => artist.name),
      });
    } else {
      props.onSubmit({
        id: props.promptData.id,
      });
    }
  }

  const debounce = (func: Function, timeout = SEARCH_DEBOUNCE_TIME_MS) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const search = debounce((query: string) => {
    if (query) {
      cancelSearch = false;
      spotifyApi
        .searchTracks(query)
        .then((response) => {
          if (!cancelSearch) {
            setSearchResults(response.tracks.items);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      cancelSearch = true;
      setSearchResults([]);
    }
  });

  return (
    <div className={css.SongPrompt}>
      <button
        className={css.SongPromptSkipButton}
        onClick={() => handleSubmit()}
      >
        Skip
      </button>
      <input
        className={css.SongPromptInput}
        type='text'
        placeholder='Start typing to search...'
        autoFocus={true}
        onChange={(event) => search(event.target.value.toUpperCase())}
      />
      <ol className={css.SongPromptResults}>
        {searchResults?.map((track: SpotifyApi.SingleTrackResponse, i) => {
          return (
            <li
              className={css.SongPromptResult}
              key={i}>
              <button
                className={css.SongPromptResultButton}
                onClick={() => handleSubmit(track)}
              >
                <img
                  alt="Artwork"
                  className={css.SongPromptResultArtwork}
                  src={track.album.images ? track.album.images[0]?.url : ""}
                />
                <div className={css.SongPromptResultDetails}>
                  <p className={css.SongPromptResultDetailsTitle}>{track.name}</p>
                  <p className={css.SongPromptResultDetailsArtists}>
                    {track.artists.map((artist) => artist.name).join(", ")}
                  </p>
                </div>
              </button>
            </li>
          );
        })}
      </ol>
    </div>
  );
}