import React, { useEffect } from "react";
import { useContext, useState } from "react";

import css from './Error.module.css';

export const ErrorContext = React.createContext<{ errorMessage?: string, clearError?: () => void }>({});

function Error(): JSX.Element | null {
  const DELAY = 2;

  const { errorMessage, clearError } = useContext(ErrorContext);
  const [isShown, setShown] = useState(true);
  const [isFading, setFadeOut] = useState(false);

  const hide = () => {
    setShown(false);
    if (clearError) {
      clearError();
    }
  };

  useEffect(
    () => {
      if (errorMessage) {
        setShown(true);
        setFadeOut(true);
        let timeout = setTimeout(() => {
          if (clearError) {
            setFadeOut(false);
          }
        }, DELAY * 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    },
    [errorMessage, clearError]
  );

  return errorMessage && isShown
    ? (
      <div className={`${css.Error} ${isFading ? css.show : css.hide}`} onClick={hide} onTransitionEnd={hide}>
        {errorMessage}
      </div>
    )
    : null;
}

export default Error;