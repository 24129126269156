import { useEffect, useRef, useState } from "react";
import Error, { ErrorContext } from "./Error";
import { RouteProps } from "react-router-dom";
import Comms, { ConnectionState } from "../comms/Comms";
import ErrorBoundary from "./ErrorBoundary";
import { MultiChoicePrompt } from "./multichoice/MultiChoicePrompt";
import { TextPrompt } from "./text/TextPrompt";

import prompts from "./Prompts.module.css";
import { ButtonPrompt } from "./button/ButtonPrompt";
import { SongPrompt } from "./song/SongPrompt";
import { MemePrompt } from "./meme/MemePrompt";

const DEBUG_MODE = false;

const PROMPTS_MAP: { [type: string]: any } = {
  button: ButtonPrompt,
  multichoice: MultiChoicePrompt,
  text: TextPrompt,
  song_search: SongPrompt,
  meme: MemePrompt,
};

const MAX_USERNAME_LENGTH = 15;

interface Props extends RouteProps {}

interface Prompt {
  promptType: string;
  promptData: any;
}

const Prompts = (props: Props) => {
  const [joined, setJoined] = useState(false);
  const [prompt, setPrompt] = useState<Prompt>();
  const [promptVisible, setPromptVisible] = useState<boolean>(true);
  const [error, setError] = useState("");

  const comms = useRef<Comms>(null);

  useEffect(() => {
    // store prompt in local storage in case of refresh
    if (prompt) sessionStorage.setItem("prompt", JSON.stringify(prompt));
  }, [prompt]);

  const sendJoinRequest = (roomCode: string, username: string) => {
    if (roomCode !== "" && username !== "") {
      comms.current?.requestJoin(roomCode, username);
    } else {
      setError("Room code and username must not be empty");
    }
  };

  const sendPromptResponse = (data: any) => {
    setPromptVisible(false);
    setError("");
    comms.current?.sendPromptResponse(data);
  };

  const onConnectionStateChanged = (state: ConnectionState) => {};

  const onJoinStateChanged = (state: boolean) => {
    setJoined(state);
  };

  const onRejectPrompt = (reason: string) => {
    setPromptVisible(true);
    setError(reason);
  };

  const clearPrompt = () => {
    setPrompt(undefined);
    setPromptVisible(false);
    sessionStorage.removeItem("prompt");
  };

  const onPromptReceived = (messageData: any) => {
    if (messageData.promptType && messageData.promptData) {
      clearPrompt();
      setPrompt({
        promptType: messageData.promptType,
        promptData: messageData.promptData,
      });
      setPromptVisible(true);
    } else {
      console.warn(
        "prompt message data malformed (missing promptType or promptData)",
        messageData
      );
    }
  };

  const renderInputPrompt = () => {
    if (prompt && prompt.promptType in PROMPTS_MAP) {
      let Prompt = PROMPTS_MAP[prompt.promptType];
      return (
        <span style={{ visibility: promptVisible ? "inherit" : "hidden" }}>
          <Prompt
            promptData={prompt.promptData}
            onSubmit={(data: any) => sendPromptResponse(data)}
          />
        </span>
      );
    } else {
      console.warn(
        "[renderInputPrompt] prompt type " + prompt?.promptType + " invalid."
      );
    }
    return null;
  };

  const renderJoinInput = () => {
    var searchParams = new URLSearchParams(window.location.search);
    var roomSearchParam = searchParams.get("room");
    if (roomSearchParam) {
      console.log("room code found in URL: " + roomSearchParam);
      // store room code in local storage
      sessionStorage.setItem("roomCode", roomSearchParam);
      // remove room code from URL
      searchParams.delete("room");
      // push state without room code
      window.history.pushState({}, "", window.location.pathname);
    }
    var roomCode = sessionStorage.getItem("roomCode") || "";
    var username = sessionStorage.getItem("username") || "";
    // Remove the room code from the URL so that it doesn't get used again if the user refreshes?
    searchParams.delete("room");
    return (
      <TextPrompt
        promptData={{
          prompts: ["ROOM CODE", "USERNAME"],
          initialValues: [roomCode, username],
          maxInputLength: MAX_USERNAME_LENGTH,
          id: "",
          uppercase: true,
        }}
        onSubmit={(data) => {
          const [roomCode, username] = data.values;
          sendJoinRequest(roomCode, username);
          // store username and room code in local storage
          if (roomCode) sessionStorage.setItem("roomCode", roomCode);
          if (username) sessionStorage.setItem("username", username);
        }}
      />
    );
  };

  const renderPrompt = () => {
    if (joined || DEBUG_MODE) {
      if (
        prompt &&
        prompt.promptType &&
        Object.keys(prompt.promptData).length !== 0
      ) {
        return renderInputPrompt();
      }
    } else {
      return renderJoinInput();
    }
    return null;
  };

  return (
    <div className={prompts.Prompts}>
      <Comms
        ref={comms}
        onConnectionStateChanged={(state) => onConnectionStateChanged(state)}
        onError={(error) => setError(error)}
        onJoinStateChanged={(state) => onJoinStateChanged(state)}
        onRejectPrompt={(reason: string) => onRejectPrompt(reason)}
        onHidePromptReceived={() => clearPrompt()}
        onPromptReceived={(data) => onPromptReceived(data)}
      />
      <ErrorContext.Provider
        value={{ errorMessage: error, clearError: () => setError("") }}
      >
        <Error />
      </ErrorContext.Provider>
      <div className={prompts.PromptsSubmissionOverlay}></div>
      <ErrorBoundary>{renderPrompt()}</ErrorBoundary>
    </div>
  );
};

export default Prompts;
