import React from "react";
import Message, { MessageType } from "./message";

const PRODUCTION = process.env.NODE_ENV === "production";
const ENDPOINT = PRODUCTION
  ? "wss://party-games-310323.ew.r.appspot.com/players"
  : "ws://localhost:8080/players";

const CONNECTION_RETRY_INTERVAL = 3000; // milliseconds

export enum ConnectionState {
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
}

export interface Props {
  onConnectionStateChanged: (state: ConnectionState) => void;
  onJoinStateChanged: (state: boolean) => void;
  onPromptReceived: (data: Message) => void;
  onHidePromptReceived: () => void;
  onRejectPrompt: (reason: string) => void;
  onError: (error: string) => void;
}

interface State {
  connectionState: ConnectionState;
  joined: boolean;
  roomCode: string;
}

class Comms extends React.Component<Props, State> {
  private webSocket?: WebSocket;

  constructor(props: Props) {
    super(props);
    this.state = {
      connectionState: ConnectionState.DISCONNECTED,
      joined: false,
      roomCode: "",
    };
  }

  componentDidMount() {
    this.connect();
  }

  private connect() {
    if (this.state.connectionState === ConnectionState.CONNECTED) {
      return;
    }
    console.log("attempting to connect...");
    this.webSocket = new WebSocket(ENDPOINT);
    this.webSocket.onopen = () => this.handleOpen();
    this.webSocket.onclose = () => this.handleClose();
    this.webSocket.onerror = (error) => this.handleError(error);
    this.webSocket.onmessage = (msg) => this.handleMessage(msg.data);
    this.setState({ connectionState: ConnectionState.CONNECTING });
  }

  private handleOpen() {
    console.log("connected");
    this.setState({ connectionState: ConnectionState.CONNECTED });
    return true;
  }

  private handleClose() {
    this.setState({
      connectionState: ConnectionState.DISCONNECTED,
      joined: false,
    });
    setTimeout(() => this.connect(), CONNECTION_RETRY_INTERVAL);
  }

  private handleError(event: Event) {
    console.log("websocket error ", event);
    this.webSocket?.close();
  }

  sendMessageToRoom(messageType: string, roomCode: string, messageData: any) {
    messageData["roomCode"] = roomCode;
    this.sendMessage(messageType, messageData);
  }

  sendMessage(messageType: string, messageData: any) {
    const message = new Message(messageType, messageData);
    this.webSocket?.send(JSON.stringify(message));
    console.log(`message sent: ${JSON.stringify(message)}`);
  }

  sendPromptResponse(messageData: any) {
    this.sendMessageToRoom(
      MessageType.Tx.PROMPT_RESPONSE,
      this.state.roomCode,
      messageData
    );
  }

  requestJoin(roomCode: string, username: string) {
    if (this.state.connectionState !== ConnectionState.CONNECTED) {
      this.props.onError("Cannot connect to server");
      return;
    }
    this.sendMessageToRoom(MessageType.Tx.REQUEST_JOIN, roomCode, {
      username: username,
      oldClientId: sessionStorage.getItem("clientId"),
    });
  }

  private validateMessage(messageRaw: any) {
    let unvalidatedMessage = JSON.parse(messageRaw);
    if (unvalidatedMessage.protocolVersion !== Message.PROTOCOL_VERSION) {
      throw Error(`incompatible message protocol version. 
        message version: ${unvalidatedMessage.protocolVersion}. our version: ${Message.PROTOCOL_VERSION}: ${unvalidatedMessage}`);
    }
    if (!("type" in unvalidatedMessage)) {
      throw Error(
        `message malformed. no message type specified: ${unvalidatedMessage}`
      );
    }
    if (!("data" in unvalidatedMessage)) {
      throw Error(
        `message malformed. no message data specified: ${unvalidatedMessage}`
      );
    }
    return new Message(unvalidatedMessage.type, unvalidatedMessage.data);
  }

  private handleMessage(messageRaw: any) {
    console.log(`message received: ${messageRaw}`);
    let message: Message;
    try {
      message = this.validateMessage(messageRaw);
    } catch (error) {
      console.debug("invalid message recieved: ", error);
      return;
    }
    switch (message.type) {
      case MessageType.Rx.HEARTBEAT_PING: {
        this.handleHeartbeatPing();
        break;
      }
      case MessageType.Rx.ACCEPT_JOIN: {
        this.handleAcceptJoin(message);
        break;
      }
      case MessageType.Rx.REJECT_JOIN: {
        this.props.onError(message.data.reason);
        break;
      }
      case MessageType.Rx.REJECT_INPUT: {
        this.props.onRejectPrompt(message.data.reason);
        break;
      }
      case MessageType.Rx.REQUEST_INPUT: {
        console.debug("Prompt received...");
        this.props.onPromptReceived(message.data);
        break;
      }
      case MessageType.Rx.HIDE_PROMPT: {
        this.props.onHidePromptReceived();
        break;
      }
      default:
        console.log(`message type ${message.type} not handled`);
        break;
    }
  }

  private handleHeartbeatPing() {
    this.sendMessage(MessageType.Tx.HEARTBEAT_PONG, {});
  }

  private handleAcceptJoin(message: Message) {
    if (!message.data.clientId || !message.data.roomCode) {
      console.debug(`invalid ${MessageType.Rx.ACCEPT_JOIN} message`);
      return;
    }
    this.setState({
      joined: true,
      roomCode: message.data.roomCode,
    });
    sessionStorage.setItem("clientId", message.data.clientId);
  }

  componentDidUpdate(_: Props, prevState: State, _snapshot: any) {
    if (prevState.connectionState !== this.state.connectionState) {
      this.props.onConnectionStateChanged(this.state.connectionState);
    }
    if (prevState.joined !== this.state.joined) {
      this.props.onJoinStateChanged(this.state.joined);
    }
  }

  render() {
    return null;
  }
}

export default Comms;
