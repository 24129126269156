export class MessageType {
  static Tx = {
    PROMPT_RESPONSE: "prompt_response",
    REQUEST_JOIN: "request_join",
    HEARTBEAT_PONG: "heartbeat_pong",
  };
  static Rx = {
    ACCEPT_JOIN: "accept_join",
    REJECT_JOIN: "reject_join",
    REJECT_INPUT: "reject_input",
    REQUEST_INPUT: "request_input",
    HIDE_PROMPT: "hide_prompt",
    HEARTBEAT_PING: "heartbeat_ping",
  };
}

class Message {
  static readonly PROTOCOL_VERSION = 1.0;

  type: string;
  data: any;
  protocolVersion: number;

  constructor(type: string, data: any) {
    this.type = type;
    this.data = data;
    this.protocolVersion = Message.PROTOCOL_VERSION;
  }
}

export default Message;
