import React, { ErrorInfo } from "react";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {    // Display fallback UI    
    this.setState({ hasError: true });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1 style={{ textAlign: 'center' }}>Something went wrong. Please refresh and try again</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;