import { PromptProps } from "../common/Prompt";

import css from './MultiChoicePrompt.module.css';

interface InputData {
  id: string;
  prompt: string;
  options: string[];
}

interface OutputData {
  id: string;
  choice: number;
}

export const MultiChoicePrompt = (props: PromptProps<InputData, OutputData>) => {

  const handleChoiceClick = (choice: number) => {
    props.onSubmit({
      id: props.promptData.id,
      choice: choice,
    });
  }

  return (
    <div className={css.MultiChoicePrompt}>
      <span className={css.MultiChoicePromptLabel}>
        {props.promptData.prompt}
      </span>
      <ol className={css.MultiChoicePromptOptions}>
        {props.promptData.options.map((item, i) => {
          return (
            <li key={i} className={css.MultiChoicePromptItem}>
              <button
                className={css.MultiChoicePromptButton}
                onClick={() => handleChoiceClick(i)}
              >
                <pre>{item}</pre>
              </button>
            </li>
          );
        })}
      </ol>
    </div>
  );
}