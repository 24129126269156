import { ChangeEvent, useEffect, useState } from "react";
import * as emem from "./emem";

import css from "./MemeCreator.module.css";

export interface Props {
  memeTemplate?: emem.MemeTemplate;
  captions?: string[];
  className?: string | undefined;
}

export const MemeCreatorComponent = (props: Props) => {
  // const imageData = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAASFBMVEX/AAD/fX3/jIz/m5v/q6v/uLj/trb/qan/ior/gYH/2dn/dHT/eHj//Pz/8/P/5OT/z8//yMj/srL/kZH/lZX/oKD/rq7/pKT5lJt8AAABl0lEQVR4nO3dS24CMRAG4TYzw2MgDwiQ+980WWQRZROE1GqVXZ8v8NfSkiXHy+vb+n4+L8tymed5N/342Py2b087bP51nR5wmx9wX/7arnGJvh1jrp6QrA1Q+Fk9IVmLqXpCMgv5LOSzkM9CPgv5Rii8Vk9I1mJTPSGZhXwW8lnIZyGfhXwW8lnIZyGfhXwW8lnIZyGfhXwW8lnIN0LhvnpCsvZ9+mYhn4V8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8FvK1OFRPSDbCi6H+X31ZSGchn4V8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8IxT2/8+MhXQW8lnIZyGfhXwW8lnIZyGfhXwW8lnIZyGfhXwW8lnIZyGfhXwjFN6qJyRrsauekKzFXD0hmYV8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8FvJZyGchn4V8Le7VE5K12FZPSNZiqZ6QzEI+C/ks5LOQz0I+C/ks5BuhsPcb8CnWY+vZafkCI00HxRaYb6EAAAAASUVORK5CYII=";

  const [creator, setCreator] = useState<emem.MemeCreator>();
  const [image, setImage] = useState<string | null>();

  useEffect(() => {
    let canvas = document.getElementById("canvas") as HTMLCanvasElement;
    canvas.width = canvas.parentElement?.clientWidth ?? 0;
    canvas.height = canvas.parentElement?.clientHeight ?? 0;
    setCreator(new emem.MemeCreator(canvas, canvas.width, canvas.height));
  }, []);

  useEffect(() => {
    if (props.memeTemplate) {
      creator?.loadTemplate(props.memeTemplate);
    }
    if (props.captions) {
      creator?.setCaptions(props.captions);
    }
  }, [creator, props.captions, props.memeTemplate]);

  useEffect(() => {
    if (!image) {
      return;
    }
    creator?.loadTemplate({
      image: image,
      captions: [],
      name: "Untitled Masterpiece",
    });
  }, [creator, image]);

  const addCaption = () => {
    creator?.addCaption("Default Text");
  };

  // const fetchAllMemeTemplates = async () => {
  //   const templates = await MemeImageApi.getAllMemeTemplates();
  //   console.log(templates);
  // };

  const onFileSelectorChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList?.length) {
      setImage(await getBase64(fileList[0]));
    }
  };

  const getBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  return (
    <div className={css.Container + " " + (props.className ?? "")}>
      <div className={css.ImageSelectionControls}>
        {/* <button
          className={css.ImageUploadButton}
          onClick={fetchAllMemeTemplates}
        >
          Find image
        </button> */}
        <label className={css.ImageUploadButton}>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={onFileSelectorChange}
          />
          Upload image...
        </label>
      </div>
      <canvas id="canvas" width="500px" height="500px" />
      <div className={css.EditControls}>
        <button className={css.AppCaptionButton} onClick={addCaption}>
          Add Caption
        </button>
      </div>
    </div>
  );
};
