
import { useLocalstorageState } from 'rooks';
import css from './App.module.css';
import Prompts from './prompts/Prompts';

const THEME = {
  light: 'light',
  dark: 'dark'
}

function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalstorageState('theme', defaultDark ? THEME.dark : THEME.light);

  const switchTheme = () => {
    const newTheme = theme === THEME.light ? THEME.dark : THEME.light;
    setTheme(newTheme);
  }

  return (
    <div className={css.App} data-theme={theme}>
      <header className={css.AppHeader}>
        <h1 className={css.AppHeaderTitle}>Jacobs' Party Pack</h1>
      </header>
      <main className={css.AppMain}>
        <Prompts />
      </main>
      <footer className={css.AppFooter}>
        <button className={css.AppThemeButton} onClick={switchTheme}>
          Switch To {theme === THEME.light ? 'Dark' : 'Light'} Mode
        </button>
      </footer>
    </div>
  );
}

export default App;
