import { PromptProps } from "../common/Prompt";

import css from './ButtonPrompt.module.css';

interface InputData {
  id: string;
  prompt: string;
}

interface OutputData {
  id: string;
}

export const ButtonPrompt = (props: PromptProps<InputData, OutputData>) => {

  const handleSubmit = () => {
    props.onSubmit({
      id: props.promptData.id,
    })
  }

  return (
    <div className={css.ButtonPrompt}>
      <button
        className={css.ButtonPromptSubmitButton}
        onClick={handleSubmit}
      >
        {props.promptData.prompt}
      </button>
    </div>
  )
}