import { fabric } from "fabric";
import { Color, MemeTemplate } from "./meme_template";

import MemeRendererBase from "./renderer_base";

const BLACK: Color = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};

const WHITE: Color = {
  r: 1,
  g: 1,
  b: 1,
  a: 1,
};

const TRANSPARENT: Color = {
  r: 0,
  g: 0,
  b: 0,
  a: 0,
};

export class MemeCreator extends MemeRendererBase<fabric.Canvas> {
  constructor(
    element: HTMLCanvasElement,
    private width: number,
    private height: number
  ) {
    super(
      new fabric.Canvas(element, {
        width: width,
        height: height,
      })
    );
  }

  async addCaption(text = "") {
    const canvasWidth = this.canvas.width ?? this.width;
    const canvasHeight = this.canvas.height ?? this.height;
    const captionTextbox = await this.memeCaptionToTextbox({
      area: {
        x: canvasWidth / 2,
        y: canvasHeight / 2,
        width: canvasWidth / 2,
        height: canvasWidth / 2,
      },
      color: WHITE,
      outlineColor: BLACK,
      backgroundColor: TRANSPARENT,
      fontFamily: "Anton",
      text: text,
      autosize: true,
      centerH: true,
      centerV: true,
      fontSize: 20,
      outlineSize: 3,
      outlineText: true,
      rotation: 0,
      uppercase: true,
    });

    this.addCaptionTextbox(captionTextbox);
  }

  asMemeTemplate(): MemeTemplate | undefined {
    if (!this.image || !this.captions) {
      return;
    }
    return {
      captions: this.captions.map(this.textboxToMemeCaption),
      image: this.image?.toDataURL({}),
      name: "",
    };
  }
}
