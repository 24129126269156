import { useCallback, useEffect, useState } from "react";
import * as emem from "./emem";

import css from './MemeRenderer.module.css';

export interface Props {
  memeTemplate: emem.MemeTemplate;
  captions: string[];
  className?: string | undefined;
}

export const MemeRenderer = (props: Props) => {
  const [renderer, setRenderer] = useState<emem.MemeRenderer>();

  const createRenderer = useCallback(async (canvas: HTMLCanvasElement) => {
    const newRenderer = new emem.MemeRenderer(canvas, canvas.width, canvas.height);
    newRenderer.loadTemplate(props.memeTemplate).then(() => setRenderer(newRenderer));
  }, [props.memeTemplate]);

  useEffect(() => {
    let canvas = document.getElementById('canvas') as HTMLCanvasElement;
    canvas.width = canvas.parentElement?.clientWidth ?? 0;
    canvas.height = canvas.parentElement?.clientHeight ?? 0;
    createRenderer(canvas);
  }, [createRenderer]);

  useEffect(() => {
    for (let i = 0; i < props.captions.length; i++) {
      renderer?.setCaptionText(i, props.captions[i]);
    }
  }, [props.captions, renderer]);

  return (
    <div className={css.MemeRenderer + ' ' + (props.className ?? '')}>
      <canvas id="canvas" />
    </div>
  );
};